import * as React from 'react';
import { Translate, useTranslate } from '@hanssens/ht-translate';
import { useResize } from '@hanssens/ht-resize';
import { format } from 'date-fns';

import { TOPDESK_DEFAULT_URI } from '../config/config';
import { ITicket } from '../interfaces/ITopDeskTicket';

import tOPdeskLogo from '../assets/images/TOPdesk_White_Logomark.svg';

import ticketTranslations from '../translations/ticket.json';
import Property from './shared/Property';

/**********************************************************************************
 * Helper functions
 **********************************************************************************/
export const getColorForStatus = (statusId: string) => {
    switch (statusId) {
        case '6777e95e-f027-5dbc-98c9-11d5abcc14be':
        case 'ba9a182e-8a1e-513c-bc2e-cdb557fe86df':
            return 'bg-success';

        case '36b8e8b8-2bd1-426c-99af-9c5a6a07e9c0':
        case '6eb69965-ff03-40c0-8946-61b7fc707675':
        case 'a45f0628-a47c-486f-9d3f-de8a473da6b6':
        case 'c919a166-d666-4391-b13c-4b59812261ab':
            return 'bg-danger';
        default:
            return 'bg-warning';
    }
};

interface ITopDeskTicketProps {
    className?: string | null | undefined;
    ticket: ITicket;
}

const TopDeskTicket: React.FC<ITopDeskTicketProps> = ({ className, ticket }) => {
    /**********************************************************************************
     * State
     **********************************************************************************/

    useTranslate(ticketTranslations);
    const { getClasses, size } = useResize();

    /**********************************************************************************
     * Render
     **********************************************************************************/

    let statusClassNames = ['text-overflow', 'font-small', 'text-white', 'px-2', 'py-1', 'text-center', 'rounded', 'ms-auto'];
    if (ticket?.processingStatus?.id) {
        statusClassNames.push(getColorForStatus(ticket.processingStatus.id));
    }

    let topdeskurl = '';
    if (ticket && ticket.id) {
        // Open in existing topdesk tab
        // let id = ticket.id.replace(/-/g, '');
        // topdeskurl = `${TOPDESK_DEFAULT_URI}tas/secure/openpage.jsp?url=/tas/secure/incident?unid=${id}`;

        // Open in new tab
        topdeskurl = `${TOPDESK_DEFAULT_URI}tas/secure/contained/incident?unid=${ticket.id}`;
    }

    return (
        <div className={'topdesk-ticket ' + className}>
            <div className='row'>
                <div className={getClasses({ sm: ['col-8'], md: ['col-9'], lg: ['col-10'] })}>
                    <div className='p-3'>
                        <div className='w-100 d-flex flex-row align-items-center'>
                            <span className='fw-bold'>{ticket?.number}</span>
                            <span className='ms-1'>{ticket?.creationDate ? format(new Date(ticket.creationDate), 'dd/MM/yyyy') : null}</span>

                            <span className={statusClassNames.join(' ')}>{ticket.processingStatus?.name}</span>
                        </div>
                        <div className='w-100 bb-xs my-2' />

                        {size === 'sm' || size === 'md' ? (
                            <Property classNames='property' label={<Translate id='ticket.operatorGroup' />} value={ticket.operatorGroup?.name} />
                        ) : (
                            <div className='row'>
                                <div className={getClasses({ sm: ['col-5'], md: ['col-4'], lg: ['col-3'] })}>
                                    <Translate id='ticket.operatorGroup' />:
                                </div>
                                <div className={getClasses({ sm: ['col-7'], md: ['col-8'], lg: ['col-9'] })}>{ticket.operatorGroup?.name}</div>
                            </div>
                        )}

                        {size === 'sm' || size === 'md' ? (
                            <Property classNames='property' label={<Translate id='ticket.operator' />} value={ticket.operator?.name} />
                        ) : (
                            <div className='row'>
                                <div className={getClasses({ sm: ['col-5'], md: ['col-4'], lg: ['col-3'] })}>
                                    <Translate id='ticket.operator' />:
                                </div>
                                <div className={getClasses({ sm: ['col-7'], md: ['col-8'], lg: ['col-9'] })}>{ticket.operator?.name}</div>
                            </div>
                        )}

                        {size === 'sm' || size === 'md' ? (
                            <Property classNames='property' label={<Translate id='ticket.briefDescription' />} value={ticket?.briefDescription} />
                        ) : (
                            <div className='w-100 mt-2'>
                                <div className='w-100'>
                                    <Translate id='ticket.briefDescription' />:
                                </div>
                                <div className='w-100 fw-bold'>{ticket?.briefDescription}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={getClasses({ sm: ['col-4'], md: ['col-3'], lg: ['col-2'] })}>
                    <a href={topdeskurl} rel='external noopener noreferrer' target='_blank'>
                        <div className='bg-primary h-100 topdesk-button'>
                            <div className='d-flex flex-column h-100 align-items-center justify-content-center'>
                                <img src={tOPdeskLogo} height='60px' className='mr-2' alt={'TOPdesk-logo'} />
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default TopDeskTicket;
