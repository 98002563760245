import { IHTStoreConfiguration } from '@hanssens/ht-store';

export const configuration: IHTStoreConfiguration = {
    newTicket: {
        initialValue: null,
        persist: true,
    },
    newCallback: {
        initialValue: null,
        persist: true,
    },
    user: {
        initialValue: null,
        persist: true,
    },
    logo: {
        initialValue: null,
        persist: true,
    },
    tickets: {
        initialValue: null,
        persist: true,
    },
    ticketCreated: {
        initialValue: false,
        persist: true,
    },
    ticketOperators: {
        initialValue: null,
        persist: true,
    },
    callbackOperators: {
        initialValue: null,
        persist: true,
    },
};
