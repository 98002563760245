import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { TOPDESK_DEFAULT_URI, TOPDESK_URI } from '../config/config';
import { testGuid } from '../utilities/guidUtil';

interface ICustomerLogoProps {
    logo: string | null;
}

const CustomerLogo: React.FC<ICustomerLogoProps> = ({ logo }) => {
    /***********************************************************************
     * State
     ***********************************************************************/
    const [searchParams] = useSearchParams();

    const id = searchParams.get('id');

    /***********************************************************************
     * Render
     ***********************************************************************/
    let link = '';
    if (id && testGuid(id)) {
        link = TOPDESK_URI.replace('{value}', id);
    } else if (id && !testGuid(id)) {
        link = TOPDESK_DEFAULT_URI;
    }

    let content = <i className='fas fa-user-circle fa-5x text-dark' />;
    if (logo) {
        content = <img src={`data:image/png;base64,${logo}`} alt='avatar' />;
    }

    if (link) {
        content = (
            <a href={link} rel='external noopener noreferrer' target='_blank'>
                {content}
            </a>
        );
    }

    return (
        <div className='border-green mb-3'>
            <div className='border-white'>
                <div className='avatar'>{content}</div>
            </div>
        </div>
    );
};

export default CustomerLogo;
