import * as React from 'react';
import { HTNoContent } from '@hanssens/ht-gui';
import { useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { Translate } from '@hanssens/ht-translate';
import { ResizeDetector, useResize } from '@hanssens/ht-resize';
import { useHTStore } from '@hanssens/ht-store';

import CustomerInfo from '../components/CustomerInfo';
import CustomerLogo from '../components/CustomerLogo';
import TopDeskTicket from '../components/TopDeskTicket';
import userApi from '../api/userApi';

import { ITicket as ITopDeskTicket } from '../interfaces/ITopDeskTicket';
import { testGuid } from '../utilities/guidUtil';
import { TOPDESK_DEFAULT_URI } from '../config/config';

// Logos
import tOPdeskLogoRGB from '../assets/images/TOPdesk_RGB_Logomark.svg';
import tOPdeskLogoWhite from '../assets/images/TOPdesk_White_Logomark.svg';

interface IMainProps {}

export interface IUser {
    id: string;
    surName: string;
    phoneNumber: string;
    branch: IBranch | null;
    department: IDepartment | null;
    email: string;
    networkLoginName: string;
}

export interface IBranch {
    id: string;
    name: string;
}

export interface IDepartment {
    id: string;
    name: string;
}

const Main: React.FC<IMainProps> = (props) => {
    const [searchParams] = useSearchParams();

    const [user, setUser] = useHTStore<IUser | null>('user');
    const [logo, setLogo] = useHTStore<string | null>('logo');
    const [tickets, setTickets] = useHTStore<ITopDeskTicket[] | null>('tickets');

    const id = searchParams.get('id');

    const { getClasses } = useResize();

    const [loadingTickets, setLoadingTickets] = React.useState<boolean>(false);

    /***********************************************************************
     * Startup
     ***********************************************************************/

    React.useEffect(() => {
        if (id && testGuid(id)) {
            userApi
                .get(`user/${id}`)
                .then((response) => {
                    setUser(response.data);
                })
                .catch((err) => {
                    console.warn(err);
                });
        }
    }, [id, setUser]);

    React.useEffect(() => {
        if (id && testGuid(id)) {
            userApi
                .get(`user/${id}/logo`)
                .then((response) => {
                    setLogo(response.data);
                })
                .catch((err) => {
                    console.warn(err);
                });
        }
    }, [id, setLogo]);

    React.useEffect(() => {
        if (id && testGuid(id)) {
            setLoadingTickets(true);
            userApi
                .get(`user/${id}/tickets`)
                .then((response) => {
                    setTickets(response.data);
                    setLoadingTickets(false);
                })
                .catch((err) => {
                    console.warn(err);
                    setLoadingTickets(false);
                });
        }
    }, [id, setTickets]);

    /***********************************************************************
     * Render
     ***********************************************************************/

    let ticketContent = null;
    if (tickets && tickets.length > 0) {
        ticketContent = (
            <>
                <div className='d-flex flex-row align-items-center'>
                    <img src={tOPdeskLogoRGB} height='12px' className='mx-2' alt={'TOPdesk-logo-RGB'} />
                    <span className='fw-bold'>
                        <Translate id='main.ticket.recent' />
                    </span>
                </div>
                <div className='px-2 ticket-container scrollable-y'>
                    <div className='inner'>
                        {tickets.map((t, i) => {
                            let classNames = ['bg-white', 'box-shadow'];
                            if (i > 0) {
                                classNames.push('mt-3');
                            }
                            return <TopDeskTicket key={t.id} className={classNames.join(' ')} ticket={t} />;
                        })}
                    </div>
                </div>
            </>
        );
    } else if (loadingTickets) {
        ticketContent = <HTNoContent title={<Translate id='main.ticket.loading' />} icon={'fas fa-spinner-third fa-spin fa-5x'} />;
    } else {
        ticketContent = <HTNoContent title={<Translate id='main.ticket.noContent' />} icon={'fas fa-ticket fa-5x'} />;
    }

    let uri = `${TOPDESK_DEFAULT_URI}tas/secure/openpage.jsp?url=${encodeURIComponent(`/tas/secure/incident?action=new&status=1`)}`;
    // if (user && user.networkLoginName) {
    //     uri = `${TOPDESK_DEFAULT_URI}tas/secure/openpage.jsp?url=%2Ftas%2Fsecure%2Fincident%3Faction%3Dnew%26replacefield0%3Dpersoonid%26searchfield0%3Dloginnaamnetwerk%26searchvalue0%3D${encodeURIComponent(
    //         user.networkLoginName
    //     )}%26status%3D1`;
    // }

    if (user && user.phoneNumber) {
        uri = `${TOPDESK_DEFAULT_URI}tas/secure/openpage.jsp?url=${encodeURIComponent(
            `/tas/secure/incident?action=new&replacefield0=persoonid&searchfield0=telefoon&searchvalue0=${encodeURIComponent(user.phoneNumber)}&status=1`
        )}`;
        // uri = `${TOPDESK_DEFAULT_URI}tas/secure/openpage.jsp?url=${encodeURIComponent(`/tas/secure/incident?action=new&replacefield0=persoonid&searchfield0=unid&searchvalue0='${user.id}'&status=1`)}`;
        // uri = `${TOPDESK_DEFAULT_URI}tas/secure/openpage.jsp?url=${encodeURIComponent(
        //     `/tas/secure/incident?action=new&replacefield0=persoonid&searchfield0=emergency_contact_phone&searchvalue0=${user.phoneNumber}&status=1`
        // )}`;
    }

    return (
        <section id='content' className='h-100 p-3'>
            <ResizeDetector />
            <div className='bg-white w-100 h-100'>
                <div className='content-container h-100'>
                    {/* Customer info */}
                    <div className='row h-100'>
                        <div className={getClasses({ shared: ['h-100'], xs: ['col-4'], md: ['col-4'], lg: ['col-3'] })}>
                            <div className='d-flex flex-column h-100'>
                                <div className='flex-grow-1 mih-0'>
                                    <div id='info' className='d-flex flex-column h-100'>
                                        <div className='d-flex flex-row mb-3'>
                                            <i className='fas fa-user text-green' />
                                            <div className='fw-bold ms-3'>
                                                <Translate id='main.contact' />
                                            </div>
                                        </div>
                                        <div className='flex-grow-1 mb-3 mih-0'>
                                            <div className='h-100'>
                                                <div className='d-flex flex-column h-100'>
                                                    <CustomerLogo logo={logo} />
                                                    <CustomerInfo user={user} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Buttons */}
                                <div id='buttons' className='d-flex'>
                                    <div className='d-flex flex-column'>
                                        <Button variant='contained' className='rounded-0' href={uri} target='_blank'>
                                            <div className='d-flex flex-row align-items-center w-100'>
                                                <img src={tOPdeskLogoWhite} alt='logo-topdesk' className='logo-topdesk' />
                                                <span className='ms-3 text-start'>
                                                    <Translate id='main.newTopDeskTicket' />
                                                </span>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={getClasses({ shared: ['h-100'], xs: ['col-8'], md: ['col-8'], lg: ['col-9'] })}>{ticketContent}</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Main;
