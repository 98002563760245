import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { TranslateProvider } from '@hanssens/ht-translate';
import { ResizeContextProvider } from '@hanssens/ht-resize';
import { HTStoreContextProvider } from '@hanssens/ht-store';

// Import store
import { configuration } from './config/storeConfig';

// Import app
import App from './pages/App';

// css
import './css/main.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    // <React.StrictMode>
    <HTStoreContextProvider configuration={configuration}>
        <TranslateProvider languages={['en', 'nl']}>
            <ResizeContextProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ResizeContextProvider>
        </TranslateProvider>
    </HTStoreContextProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
