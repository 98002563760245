import { Translate } from '@hanssens/ht-translate';
import * as React from 'react';
import { IUser } from '../pages/Main';

import Property from './shared/Property';

interface ICustomerInfoProps {
    user: IUser | null;
}

const CustomerInfo: React.FC<ICustomerInfoProps> = ({ user }) => {
    /***********************************************************************
     * Render
     ***********************************************************************/
    let content = null;
    if (user) {
        content = (
            <>
                <Property label={<Translate id='main.surname' />} value={user?.surName} />
                <Property label={<Translate id='main.phoneNumber' />} value={user?.phoneNumber} withCopy />
                <Property label={<Translate id='main.email' />} value={user?.email} withCopy />
                <Property label={<Translate id='main.branch' />} value={user?.department?.name} />
                <Property label={<Translate id='main.networkLoginName' />} value={user?.networkLoginName} />
            </>
        );
    }

    return <div className='h-100 scrollable-y'>{content}</div>;
};

export default CustomerInfo;
